$(document).ready(function () {
  /*** Header fixed ***/
  $(window).scroll(function () {
    var scrollPixel = $(window).scrollTop();
    if (scrollPixel < 100) {
      $('header').removeClass('headerFix');
    } else {
      $('header').addClass('headerFix');
    };
  });
  headerResize();

  $(function () {
    $('#formUrl').ebcaptcha();
  });
  var randomNr1 = 0;
  var randomNr2 = 0;
  var totalNr = 0;

  randomNr1 = Math.floor(Math.random() * 10);
  randomNr2 = Math.floor(Math.random() * 10);
  totalNr = randomNr1 + randomNr2;
  jQuery.fn.ebcaptcha = function (options) {
    var input = $('#ebcaptchainput');
    var label = $('#ebcaptchatext');
    var validMsg = $('#captchaAlert');
    var texti = "What is " + randomNr1 + " + " + randomNr2;
    $(label).text(texti);
    $(input).keyup(function () {
      var nr = $("#ebcaptchainput").val();
      if (nr == totalNr) {
        validMsg.hide();
        $(input).removeClass('no-valid');
        $(input).addClass('valid');
      }
      else {
        validMsg.show();
        $(input).removeClass('valid');
        $(input).addClass('no-valid');
      }
    });
  };

  $("#formUrl").on("submit", function (e) {
    e.preventDefault();
    var webUrl = $("#webUrl");
    var webUrlVal = $("#webUrl").val();
    var urlFormat = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;
    var webUrlmsg = $(".webUrlmsg");
    var phishingUrls = ["https://rebrand.ly/rxf0zs4"];
    var safeUrls = ["https://www.navines.com"];
    if (webUrlVal == "") {
      webUrlmsg.show().html("Please enter URL!");
    } else if (jQuery.inArray(webUrlVal, phishingUrls) !== -1) {
      $("#respose").html(`<div class="bd-callout bd-callout-danger"><strong>Current status</strong><h4><i class="fa-solid fa-triangle-exclamation text-danger"></i> This site is unsafe</h4>The site ${webUrlVal} contains harmful content, including pages that:<br/><ul><li>Try to trick visitors into sharing personal info or downloading software</li></ul></div>`);   
    } else if (webUrlVal.match(urlFormat)) {
      if (jQuery.inArray(webUrlVal, safeUrls) !== -1) {
        $("#respose").html(`<div class="bd-callout bd-callout-success"><strong>Current status</strong><br> <h4><i class="fa-solid fa-circle-check text-success"></i> No unsafe content found</h4></div>`);
      }else{
        $("#error_message").html("").hide();
        webUrlmsg.html("").hide();
        webUrl.removeClass('no-valid');
        webUrl.addClass('valid');
        $.ajax({
          type: "POST",
          url: "form.php",
          data: "webUrl=" + webUrlVal,
          success: function (data) {
            $("#respose").html("");
            $("#error_message").show().html("Due to a system load, try again in a few minutes");
          }
        });
      }     
    } else {
      webUrlmsg.show().html("Please Valid enter URL!");
      $("#error_message").html("").hide();
      webUrl.removeClass('valid');
      webUrl.addClass('no-valid');
    }
  });

  // Contact Form Validation
  window.addEventListener('load', function () {
    var forms = document.getElementsByClassName('needs-validation');
    var validation = Array.prototype.filter.call(forms, function (form) {
      form.addEventListener('submit', function (event) {
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }
        form.classList.add('was-validated');
      }, false);
    });
  }, false);
});


/*** Mobile Header toggle ***/
function headerResize() {
  var screen_width = $(window).width();
  if (screen_width < 991) {
    $('.navbar-toggler').off('click');
    $('.navbar-toggler').on('click', function () {
      $('header nav').toggleClass('navbar-show');
      $('#divNavbar').slideToggle(200);
    });

    $("#divNavbar > ul > li.dropdown > .dropdown-menu").hide();
    $('#divNavbar > ul > li.dropdown > .arrow').off('click');
    $('#divNavbar > ul > li.dropdown > .arrow').on('click', function (event) {
      event.preventDefault();
      $(this).closest('#divNavbar > ul > li.dropdown').find('> .dropdown-menu').slideToggle('slow');
      $(this).parent().toggleClass('open-dropdown');
    });
  } else {
    $('header nav').removeClass('navbar-show');
    $('.navbar-toggler').attr('aria-expanded', 'false');
    $("#divNavbar > ul > li.dropdown > .dropdown-menu").removeAttr("style");
    $("header .navbar-collapse").removeAttr("style").removeClass('show');
  }
}

$(window).resize(function () {
  headerResize();
});

/***********************/
/****$("#formUrl").on("submit", function (e) {
  e.preventDefault();
  var webUrl = $("#webUrl");
  var webUrlVal = $("#webUrl").val();
  var urlFormat = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;
  var webUrlmsg = $(".webUrlmsg");
  if (webUrlVal == "") {
    webUrlmsg.show().html("Please enter URL!");
  } else if (webUrlVal.match(urlFormat)) {
    $("#error_message").html("").hide();
    webUrlmsg.html("").hide();
    webUrl.removeClass('no-valid');
    webUrl.addClass('valid');
    var payload = {
      "client": {
        "clientId": "898722156399-3752id84d0bh5u3h6ec3fb4pi4betuut.apps.googleusercontent.com",
        "clientVersion": "1.5.2",
      },
      "threatInfo": {
        "threatTypes": ["MALWARE", "SOCIAL_ENGINEERING"],
        "platformTypes": ["WINDOWS"],
        "threatEntryTypes": ["URL"],
        "threatEntries": [{"url":webUrlVal}]
      }
    };
    $.ajax({
      type: "POST",
      contentType: "application/json",
      url: "https://safebrowsing.googleapis.com/v4/threatMatches:find?key=AIzaSyD-yWAaJYTo4nhJl6KOMaVLDDGKfDhtIOE",
      dataType: "json",
      data: JSON.stringify(payload),
      success: function (data) {
        var newData = JSON.stringify(data);
        var json = JSON.parse(newData);
        var threatType = json.matches;
        console.log(threatType);
        if(threatType != undefined){
          console.log(newData.length);
          $("#respose").html(`<div class="bd-callout bd-callout-danger"><strong>Current status</strong><h4><i class="fa-solid fa-triangle-exclamation text-danger"></i> This site is unsafe</h4>The site ${webUrlVal} contains harmful content, including pages that:<br/><ul><li>Try to trick visitors into sharing personal info or downloading software</li></ul></div>`);
        }else{
          console.log(json);
          $("#respose").html(`<div class="bd-callout bd-callout-success"><strong>Current status</strong><br> <h4><i class="fa-solid fa-circle-check text-success"></i> No unsafe content found</h4></div>`);
        }
      },
      error: function (status) {        
        console.log(status);
        $("#error_message").show().html("Something went wrong, Please try again later");
      }
    });
  } else {
    webUrlmsg.show().html("Please Valid enter URL!");
    $("#error_message").html("").hide();
    webUrl.removeClass('valid');
    webUrl.addClass('no-valid');
  }
});****/